import React from 'react'
import { View, StyleSheet } from 'react-native'
import LogoYoma from '../LogoYoma'
import UserNav from './UserNav'
import AuthContext from '../../contexts/AuthContext'
import HomeContext from '../../contexts/HomeContext'
import ButtonCreateUser from '../ButtonCreateUser';

type Props = {
  style: any,
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderColor: 'rgba(0,0,0,.05)',
  },
  imageStyle: {
    height: 60,
    width: 78,
  },
  logo: {
    marginRight: 'auto',
  },
})


export default class extends React.Component<Props> {
  constructor(props) {
    super(props)

    this.state = {
      displayUserForm: false,
    }

    this.toggleUserForm = () => {
      this.setState(prevState => ({
        displayUserForm: !prevState.displayUserForm,
      }))
    }
  }
  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        <LogoYoma style={styles.logo} imageStyle={styles.imageStyle} />
        <ButtonCreateUser onPress={this.toggleUserForm} />
        {this.props.renderUserForm(this.state.displayUserForm, this.toggleUserForm)}
        <AuthContext.Consumer>
          {({ updateToken }) => (
            <HomeContext.Consumer>
              {({ metadata }) => (
                <UserNav metadata={metadata} signOut={() => updateToken(null)} />
              )}
            </HomeContext.Consumer>
          )}
        </AuthContext.Consumer>
      </View>
    )
  }
}
