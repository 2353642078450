import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native'

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  closeIcon: {
    right: 35,
    top: 25,
    position: 'absolute',
  },
})

type Props = {
  onPress?: void,
  style?: any,
}

const Hitbox = (props: Props) => (
  <TouchableWithoutFeedback onPress={props.onPress}>
    <View style={[styles.container, props.style]}>
      <View style={styles.closeIcon}>
        <FontAwesomeIcon icon="times" size="2x" color="white" />
      </View>
    </View>
  </TouchableWithoutFeedback>
)

export default Hitbox
