import React from 'react'
import Slider from 'react-slick'
import { Heading3 } from '../Text'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './photos.css'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  adaptiveHeight: true,
  slidesToScroll: 1
}

export default ({ photos }) => (
  <div className="invoice-photos">
    <Heading3>Invoice photos{photos.length > 1 ? ` (${photos.length} photos)` : ''}</Heading3>
    <Slider {...settings}>
      {photos.map(photo => (
        <div key={photo.id}>
          <img className="invoice-photo-source" src={photo.location} alt={photo.id} />
        </div>
      ))}
    </Slider>
  </div>
)
