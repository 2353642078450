import React from 'react'
import ReactNative, { StyleSheet } from 'react-native'
import Fonts from './Fonts'
import Colors from './Colors'

const FONT_RATIO = 1

const styles = StyleSheet.create({
  text: {
    fontFamily: Fonts.default,
  },
  h1: {
    fontFamily: Fonts.h1,
    fontSize: Fonts.normalize(30 * FONT_RATIO),
    lineHeight: Fonts.lineHeight(37 * FONT_RATIO),
    color: Colors.blue,
  },
  h2: {
    fontFamily: Fonts.h2,
    fontSize: Fonts.normalize(23 * FONT_RATIO),
    lineHeight: Fonts.lineHeight(27 * FONT_RATIO),
    color: Colors.tangaroa,
    letterSpacing: -0.24,
  },
  h3: {
    fontFamily: Fonts.h3,
    fontSize: Fonts.normalize(17 * FONT_RATIO),
    lineHeight: Fonts.lineHeight(20 * FONT_RATIO),
    color: Colors.sapphire,
    letterSpacing: -0.11,
  },
  h4: {
    fontFamily: Fonts.h4,
    fontSize: Fonts.normalize(13 * FONT_RATIO),
    lineHeight: Fonts.lineHeight(22 * FONT_RATIO),
    color: Colors.tangaroa,
  },
  h5: {
    fontFamily: Fonts.helvetica,
    fontSize: Fonts.normalize(13 * FONT_RATIO),
    lineHeight: Fonts.lineHeight(22 * FONT_RATIO),
    color: Colors.tangaroa,
  },
  p: {
    fontFamily: Fonts.p,
    fontSize: Fonts.normalize(17 * FONT_RATIO),
    lineHeight: Fonts.lineHeight(25 * FONT_RATIO),
    color: Colors.tangaroa,
  },
  // a: {
  //   color: Colors.blue,
  //   textDecorationLine: 'underline',
  // },
  hr: {
    height: 1,
    backgroundColor: Colors.black,
  },
  headerTitle: {
    fontFamily: Fonts.fontWithWeight('helvetica'),
    fontSize: 20 * FONT_RATIO,
  },
})

export function Text({
  style,
  ...props
}) {
  return <ReactNative.Text style={[styles.text, style]} {...props} />
}

export function Heading1({
  style,
  ...props
}) {
  return <ReactNative.Text style={[styles.h1, style]} {...props} />
}

export function Heading2({
  style,
  ...props
}) {
  return <ReactNative.Text style={[styles.h2, style]} {...props} />
}

export function Heading3({
  style,
  ...props
}) {
  return <ReactNative.Text style={[styles.h3, style]} {...props} />
}

export function Heading4({
  style,
  ...props
}) {
  return <ReactNative.Text style={[styles.h4, style]} {...props} />
}

export function Heading5({
  style,
  ...props
}) {
  return <ReactNative.Text style={[styles.h5, style]} {...props} />
}

export function Paragraph({
  style,
  ...props
}) {
  return <ReactNative.Text style={[styles.p, style]} {...props} />
}

export function HeaderTitle({
  style,
  ...props
}) {
  return <ReactNative.Text style={[styles.headerTitle, style]} {...props} />
}
