import React from 'react'
import { View, StyleSheet } from 'react-native'
import moment from 'moment'

import { Heading3, Heading4 } from './Text'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  column: {
    flexBasis: '50%',
    marginBottom: 10,
  },
})


export default ({ invoice }) => (
  <View style={styles.container}>
    <View style={styles.column}>
      <Heading3>Invoice number</Heading3>
      <Heading4>{invoice.number}</Heading4>
    </View>
    <View style={styles.column}>
      <Heading3>Amount</Heading3>
      <Heading4>MMK {invoice.amount}</Heading4>
    </View>
    <View style={styles.column}>
      <Heading3>Delivery date</Heading3>
      {invoice.delivery_date && <Heading4>{moment(invoice.delivery_date).format('hh:mm:ss, Do MMMM YYYY')}</Heading4>}
    </View>
    <View style={styles.column}>
      <Heading3>Finance amount</Heading3>
      <Heading4>MMK {invoice.finance_amount}</Heading4>
    </View>
  </View>
)
