import React from 'react'
import {
  View,
  StyleSheet,
  TextInput,
} from 'react-native'
import { Heading3, Heading4 } from './Text'
import Colors from './Colors'

const styles = StyleSheet.create({
  container: {
    marginBottom: 6,
  },
  input: {
    flex: 1,
    fontSize: 15,
    height: 40,
    paddingHorizontal: 5,
  },
  inputWrapper: {
    borderColor: Colors.gray,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  label: {
    marginBottom: 10,
  },
})


export default function TextField({
  icon,
  textStyle,
  label,
  value,
  placeholder,
  keyboardType,
  onChangeText,
  secureTextEntry,
  autoCapitalize,
  helpText,
}) {
  return (
    <View style={styles.container}>
      {label && <Heading3 style={styles.label}>{label}</Heading3>}
      <View style={styles.inputWrapper}>
        {icon && (
          <View style={styles.iconWrapper}>
            {/* <MaterialIcons name={icon} size={24} color={Colors.sapphire} /> */}
          </View>
        )}
        <TextInput
          editable
          maxLength={40}
          style={[styles.input, textStyle]}
          keyboardType={keyboardType}
          autoCapitalize={autoCapitalize}
          underlineColorAndroid="transparent"
          placeholder={placeholder}
          value={value}
          secureTextEntry={secureTextEntry}
          onChangeText={onChangeText}
        />
      </View>
      {helpText && <Heading4>{helpText}</Heading4>}
    </View>
  )
}

TextField.defaultProps = {
  keyboardType: 'default',
  secureTextEntry: false,
}
