import React from 'react'
import {
  View, StyleSheet, Text, TouchableOpacity,
} from 'react-native'
import { Heading3, Heading4 } from '../Text'
import Colors from '../Colors'
import OptionMenu from '../OptionMenu'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoBlock: {
    marginHorizontal: 8,
  },
  avatar: {
    backgroundColor: Colors.blue,
    height: 40,
    width: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  nameInit: {
    color: Colors.white,
  },
})

const USER_MENU_ITEMS = {
  sign_out: 'Sign out',
}

type Props = {
  style: any,
  navigation: any,
  signOut: void,
  metadata?: {
    username: string,
    display_name: string,
  }
}

class UserNav extends React.PureComponent<Props> {
  constructor(props) {
    super(props)
    this.state = {
      displayDropdown: false,
    }
    this.toggleUserDropdown = () => {
      this.setState(prevState => ({
        displayDropdown: !prevState.displayDropdown,
      }))
    }
    this.handleSelectOption = (item) => {
      switch (item.key) {
        case 'sign_out':
          this.props.signOut()
          break
        default:
          break
      }
    }
  }

  getInitialName() {
    if (this.props.metadata && this.props.metadata.display_name) {
      return this.props.metadata.display_name[0].toUpperCase()
    }
    return 'Y'
  }

  render() {
    return (
      <TouchableOpacity
        style={[styles.container, this.props.style]}
        onPress={this.toggleUserDropdown}
      >
        {this.props.metadata && (
          <View style={styles.infoBlock}>
            <Heading3>{this.props.metadata.display_name}</Heading3>
            <Heading4>{this.props.metadata.username}</Heading4>
          </View>
        )}
        <View style={styles.avatar}>
          <Text style={styles.nameInit}>{this.getInitialName()}</Text>
        </View>
        <OptionMenu
          visible={this.state.displayDropdown}
          onRequestClose={this.toggleUserDropdown}
          options={USER_MENU_ITEMS}
          optionClicked={this.handleSelectOption}
        />
      </TouchableOpacity>
    )
  }
}

export default UserNav
