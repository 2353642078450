import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import FormSignIn from '../../components/FormSignIn'
import LogoYoma from '../../components/LogoYoma'
import request from '../../request'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    width: 400,
  },
  errorMessage: {
    marginTop: 10,
  },
})

export default class extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
    this.signIn = async (formValue) => {
      await this.setState({
        error: null,
      })
      request.post('/login', formValue)
        .then(({ data }) => {
          this.props.updateToken(data.token)
        })
        .catch((error) => {
          this.setState({
            error: error.response.data.error,
          })
        })
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.wrapper}>
          <LogoYoma />
          <FormSignIn handleSignIn={this.signIn} />
          {this.state.error && <Text style={styles.errorMessage}>{this.state.error}</Text>}
        </View>
      </View>
    )
  }
}
