import React from 'react'
import {
  FlatList,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'
import Hitbox from './Hitbox'
import Colors from './Colors'

const styles = StyleSheet.create({
  container: {
    top: 70,
    zIndex: 2,
    width: 200,
    right: 0,
    position: 'fixed',
    backgroundColor: Colors.smokeWhite,
  },
  dropdownLine: {
    paddingVertical: 10,
    flexDirection: 'row',
    paddingHorizontal: 15,
    justifyContent: 'space-between',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
})

type Props = {
  onRequestClose: () => void,
  options: any,
  style?: any,
  visible?: boolean,
  optionClicked?: () => void,
}

type LineProps = {
  text: string,
  onPress?: () => void,
}

export function DropdownLine({ onPress, text }: LineProps) {
  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={0.5}
      style={styles.dropdownLine}
      accessibilityTraits="button"
    >
      <Text>{text}</Text>
    </TouchableOpacity>
  )
}

export default function OptionMenu(props: Props) {
  let options
  if (Array.isArray(props.options)) {
    options = props.options // eslint-disable-line prefer-destructuring
  } else {
    options = Object.keys(props.options).map(key => ({
      key,
      value: props.options[key],
    }))
  }
  if (!props.visible) {
    return null
  }
  return (
    <View style={styles.overlay}>
      <Hitbox onPress={props.onRequestClose} />
      <FlatList
        style={[styles.container, props.style]}
        data={options}
        keyExtractor={item => String(item.key)}
        renderItem={({ item }) => (
          <DropdownLine
            text={item.value}
            onPress={() => {
              props.onRequestClose()
              if (item.handler) {
                item.handler()
              } else {
                props.optionClicked(item)
              }
            }}
          />
        )}
      />
    </View>
  )
}
