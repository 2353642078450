import React from 'react'
import { View, StyleSheet } from 'react-native'
import pick from 'lodash.pick'
import Colors from './Colors'
import Form from './Form'
import Hitbox from './Hitbox'
import { Heading4 } from './Text'
import Button from './Button'


const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.8)',
    zIndex: 2,
  },
  overlay: {
    zIndex: 0,
  },
  modal: {
    width: 300,
    backgroundColor: Colors.white,
    padding: 10,
  },
  success: {
    color: Colors.blue,
  },
})

export default class extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      formKey: Math.random(),
    }

    this.handleSubmit = (formValues) => {
      this.props.onSubmit(formValues, this.props.user)
    }

    this.deleteUser = () => {
      if (window.confirm('Are you sure you want to delete this account?')) {
        this.props.deleteUser()
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submitStatus === 1 && this.props.submitStatus === 2) {
      this.setState({
        formKey: Math.random(),
      })
    }
  }

  render() {
    const { close, submitStatus, user } = this.props
    const editMode = !!user
    const successMessage = editMode ? 'This account has been updated successfully' : 'The account has been created successfully'
    return (
      <View style={styles.container}>
        <Hitbox style={styles.overlay} onPress={close} />
        <View style={styles.modal}>
          <Form
            key={this.state.formKey}
            fields={[{
              name: 'username',
              label: 'Account',
              placeholder: 'Email or phone number',
              rule: 'required|between:3,30',
              helpText: 'This is required field and the length must be between 3 to 30.',
            }, {
              name: 'display_name',
              label: 'Display name',
              placeholder: 'Customer display name',
              rule: 'required|between:3,30',
              helpText: 'This is required field and the length must be between 3 to 30.',
            }, {
              name: 'password',
              label: 'Password',
              placeholder: 'Password',
              rule: editMode ? '' : 'required',
              helpText: editMode ? 'Leave it blank if you don\'t want to change password' : 'This is required field.',
            }]}
            initialValues={pick(user, ['username', 'display_name'])}
            submitButtonProps={{
              caption: 'Submit',
              captionFontSize: 18,
              style: styles.button,
              theme: 'kiuRed',
            }}
            onSubmit={this.handleSubmit}
            submitting={submitStatus === 1}
          />
          {editMode && <Button caption="Delete this account" onPress={this.deleteUser} />}
          {submitStatus === 2 && <Heading4 style={styles.success}>{successMessage}</Heading4>}
        </View>
      </View>
    )
  }
}
