import React, { Component } from 'react'
import { ActivityIndicator } from 'react-native'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faEdit } from '@fortawesome/free-solid-svg-icons'

import SignIn from './views/SignIn'
import AuthContext from './contexts/AuthContext'
import request from './request'
import Home from './views/Home'

library.add(faTimes, faEdit)


class App extends Component {
  constructor(props) {
    super(props)

    this.updateToken = async (token = null) => {
      if (!token) {
        localStorage.removeItem('token')
        delete request.defaults.headers.common.Authorization
      } else {
        localStorage.setItem('token', token)
      }
      this.setState({ token })
    }

    this.state = {
      token: undefined,
      updateToken: this.updateToken,
      me: {},
    }
  }

  async componentDidMount() {
    const token = localStorage.getItem('token')
    this.setState({ token })
  }

  render() {
    if (this.state.token === undefined) {
      return (
        <ActivityIndicator />
      )
    } if (this.state.token === null) {
      return (
        <SignIn updateToken={this.updateToken} />
      )
    }
    return (
      <AuthContext.Provider value={this.state}>
        <Home />
      </AuthContext.Provider>
    )
  }
}

export default App;
