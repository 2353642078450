import React from 'react'
import { StyleSheet } from 'react-native'
import Form from '../components/Form'

const styles = StyleSheet.create({
  formStyle: {
    marginTop: 20,
  },
  button: {
    height: 46,
  },
})

type Props = {
  handleSignIn: void,
}

export default ({ handleSignIn }: Props) => (
  <Form
    style={styles.formStyle}
    fields={[{
      name: 'username',
      label: 'Account',
      placeholder: 'Email or phone number',
      rule: 'required',
      autoCorrect: false,
      autoCapitalize: 'none',
      keyboardType: 'default',
    }, {
      name: 'password',
      label: 'Password',
      placeholder: 'Password',
      rule: 'required',
      password: true,
      secureTextEntry: true,
      autoCorrect: false,
      autoCapitalize: 'none',
      textContentType: 'password',
    }]}
    submitButtonProps={{
      caption: 'Sign In',
      captionFontSize: 18,
      style: styles.button,
      theme: 'kiuRed',
    }}
    onSubmit={handleSignIn}
  />
)
