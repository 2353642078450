import React from 'react'
import { View, StyleSheet } from 'react-native'
import { matchPath } from 'react-router-dom'
import moment from 'moment'
import { Heading4, Heading3 } from './Text'
import Colors from './Colors'


const styles = StyleSheet.create({
  container: {
    padding: 10,
    borderBottomWidth: 1,
    borderColor: 'rgba(0,0,0,.05)',
  },
  activeMenuItem: {
    backgroundColor: '#e8eaed',
  },
  invoiceDetails: {
    flexDirection: 'row',
  },
  invoiceColumn: {
    flex: 1,
  },
  newCircle: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: Colors.green,
    marginLeft: 5,
  },
  timestamp: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

function extractInvoiceId() {
  const match = matchPath(window.location.pathname, {
    path: '/users/:user_id/invoices/:invoice_id',
  })
  return match
}

const InvoiceMenuItem = ({ invoice }) => {
  const containerStyles = [styles.container]
  const match = extractInvoiceId()
  if (match && String(invoice.id) === match.params.invoice_id) {
    containerStyles.push(styles.activeMenuItem)
  }

  return (
    <View style={containerStyles}>
      <Heading3>{invoice.number}</Heading3>
      <View style={styles.invoiceDetails}>
        <View style={styles.invoiceColumn}>
          <Heading4>Amount:</Heading4>
          <Heading4>MMK {Number(invoice.amount)}</Heading4>
        </View>
        <View style={styles.invoiceColumn}>
          <Heading4>Finance amount:</Heading4>
          <Heading4>MMK {invoice.finance_amount}</Heading4>
        </View>
      </View>
      <View style={styles.timestamp}>
        <Heading4>{moment(invoice.created_at).fromNow()}</Heading4>
        {invoice.newInvoice && <View style={styles.newCircle} />}
      </View>
    </View>
  )
}

export default InvoiceMenuItem
