import React from 'react'
import { View, StyleSheet } from 'react-native'
import request from '../request'
import Photos from '../components/Photos'
import InvoiceDetail from '../components/InvoiceDetail'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
})

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      photos: [],
      invoice: {},
    }
  }
  componentDidMount() {
    request(`/api/users/${this.props.match.params.user_id}/invoices/${this.props.match.params.invoice_id}/photos`).then(({ data }) => this.setState({ photos: data }))
    request(`/api/users/${this.props.match.params.user_id}/invoices/${this.props.match.params.invoice_id}`).then(({ data }) => this.setState({ invoice: data }))
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.invoice_id !== prevProps.match.params.invoice_id) {
      this.invoiceIdChanged()
    }
  }

  invoiceIdChanged() {
    request(`/api/users/${this.props.match.params.user_id}/invoices/${this.props.match.params.invoice_id}/photos`).then(({ data }) => this.setState({ photos: data }))
    request(`/api/users/${this.props.match.params.user_id}/invoices/${this.props.match.params.invoice_id}`).then(({ data }) => this.setState({ invoice: data }))
  }

  render() {
    return (
      <View style={styles.container}>
        {this.state.invoice && <InvoiceDetail invoice={this.state.invoice} />}
        {this.state.photos.length > 0 && <Photos photos={this.state.photos} />}
      </View>
    )
  }
}
