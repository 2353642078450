const DEVICE_SCALE = 1

const DEFAULT_FONT = 'helvetica'
const SECONDARY_FONT = 'helvetica'

function normalize(size) {
  return Math.round(DEVICE_SCALE * size)
}

function lineHeight(
  val = 1,
  scale = 1,
  normalized = true,
) {
  const adjusted = normalized ? normalize(val) : val
  return Math.round(adjusted * scale)
}

function fontWithWeight(family = DEFAULT_FONT) {
  return family
}

export default {
  default: DEFAULT_FONT,
  helvetica: DEFAULT_FONT,
  basis: SECONDARY_FONT,
  h1: DEFAULT_FONT,
  h2: DEFAULT_FONT,
  h3: DEFAULT_FONT,
  h4: DEFAULT_FONT,
  p: DEFAULT_FONT,
  button: DEFAULT_FONT,
  normalize,
  lineHeight,
  fontWithWeight,
}
