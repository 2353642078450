import React from 'react'
import { Link } from 'react-router-dom'
import request from '../request'
import InvoiceMenuItem from '../components/InvoiceMenuItem'
import InvoiceContext from '../contexts/InvoiceContext'
import NoContent from '../components/NoContent'

class Invoices extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      invoices: [],
      noContent: true,
    }
  }

  componentDidMount() {
    request.get(`/api/users/${this.props.match.params.user_id}/invoices`)
      .then(({ data }) => {
        const noContent = data.length === 0
        return this.setState({ invoices: data, noContent })
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.user_id !== prevProps.match.params.user_id) {
      this.userIdChanged()
    }
    if (this.props.newInvoices.length > 0 && prevProps.newInvoices.length === 0) {
      this.setState((prevState) => {
        const currentIds = prevState.invoices.map(invoice => invoice.id)
        const newInvoices = this.props.newInvoices
          .filter(invoice => !~currentIds.indexOf(invoice.id))
          .map(invoice => Object.assign({}, invoice, {
            newInvoice: true,
          }))
        return {
          invoices: [...newInvoices, ...prevState.invoices],
        }
      })
      this.props.clearNewInvoices()
    }
  }

  userIdChanged() {
    request.get(`/api/users/${this.props.match.params.user_id}/invoices`)
      .then(({ data }) => {
        const noContent = data.length === 0
        return this.setState({ invoices: data, noContent })
      })
  }

  render() {
    return (
      <div className="second-col">
        {this.state.invoices.map((item) => (
          <Link to={`/users/${this.props.match.params.user_id}/invoices/${item.id}`} key={item.id}>
            <InvoiceMenuItem invoice={item} />
          </Link>
        ))}
        {this.state.noContent && <NoContent text="This customer has no invoice" />}
      </div>
    )
  }
}

export default (props) => (
  <InvoiceContext.Consumer>
    {contextProps => (
      <Invoices {...props} {...contextProps} />
    )}
  </InvoiceContext.Consumer>
)
