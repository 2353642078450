import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import Colors from './Colors'

const styles = StyleSheet.create({
  container: {
  },
  button: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  caption: {
    fontSize: 15,
    textAlign: 'center',
  },
  inactiveButton: {
    backgroundColor: 'rgba(223, 92, 94, 0.8)',
  },
})


function getTheme(theme) {
  let buttonTheme
  let captionTheme
  switch (theme) {
    case 'kiuRed':
      buttonTheme = { backgroundColor: Colors.kiuRed }
      captionTheme = { color: Colors.white }
      break
    case 'green':
      buttonTheme = { backgroundColor: Colors.gray }
      captionTheme = { color: '#555' }
      break
    case 'link':
      buttonTheme = { backgroundColor: Colors.transparent }
      captionTheme = { color: Colors.kiuRed }
      break
    case 'white':
    default:
      buttonTheme = { backgroundColor: Colors.white }
      captionTheme = { color: Colors.kiuRed }
      break
  }
  return { buttonTheme, captionTheme }
}

export default function Button(props) {
  const { buttonTheme, captionTheme } = getTheme(props.theme)
  let overridedCaptionStyle
  if (props.captionColor) {
    overridedCaptionStyle = {
      color: props.captionColor,
    }
  }
  if (props.captionFontSize) {
    if (overridedCaptionStyle) {
      overridedCaptionStyle.fontSize = props.captionFontSize
    } else {
      overridedCaptionStyle = {
        fontSize: props.captionFontSize,
      }
    }
  }
  const buttonStyles = [styles.button, buttonTheme]
  if (props.disabled) {
    buttonStyles.push(styles.inactiveButton)
  }
  const content = (
    <View style={buttonStyles}>
      <Text
        style={[styles.caption, captionTheme, overridedCaptionStyle]}
      >
        {props.caption}
      </Text>
      {props.loading && <ActivityIndicator size="small" color={Colors.white} />}
    </View>
  )
  let borderStyle
  if (props.borderColor) {
    borderStyle = {
      borderWidth: 1,
      borderColor: props.borderColor,
    }
  }

  if (props.onPress && !props.disabled) {
    return (
      <TouchableOpacity
        accessibilityTraits="button"
        onPress={props.onPress}
        activeOpacity={0.5}
        style={[styles.container, borderStyle, props.style]}
      >
        {content}
      </TouchableOpacity>
    )
  }
  return (
    <View style={[styles.container, borderStyle, props.style]}>
      {content}
    </View>
  )
}

Button.defaultProps = {
  style: null,
  caption: '',
  borderColor: null,
  captionColor: null,
}
