import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { matchPath } from 'react-router-dom'

import { Heading4, Heading3 } from './Text'
import Colors from './Colors'

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  activeMenuItem: {
    backgroundColor: '#1a73e8',
  },
  new: {
    width: 6,
    height: 6,
    position: 'absolute',
    right: 10,
    top: 15,
    backgroundColor: Colors.green,
    borderRadius: 3,
  },
  menuItem: {
    flexDirection: 'row',
  },
  editButton: {
    width: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F1F1F1',
  },
})

function extractInvoiceId() {
  const match = matchPath(window.location.pathname, {
    path: '/users/:user_id',
  })
  return match
}

const UserMenuItem = ({ user, editUser }) => {
  const containerStyles = [styles.container]
  const activeTextStyles = []
  const editButtonStyles = [styles.editButton]
  let editIconColor = Colors.grey
  const match = extractInvoiceId()
  if (match && String(user.id) === match.params.user_id) {
    containerStyles.push(styles.activeMenuItem)
    activeTextStyles.push({ color: '#FFF' })
    editButtonStyles.push({ backgroundColor: '#3784e8' })
    editIconColor = Colors.white
  }
  return (
    <View style={styles.menuItem}>
      <View style={containerStyles}>
        <Heading3 style={activeTextStyles}>{user.username}</Heading3>
        <Heading4 style={activeTextStyles}>{user.display_name}</Heading4>
        {user.hasNewInvoice && <View style={styles.new} />}
      </View>
      <TouchableOpacity style={[editButtonStyles]} onPress={editUser}>
        <FontAwesomeIcon icon="edit" size="1x" color={editIconColor} />
      </TouchableOpacity>
    </View>
  )
}

export default UserMenuItem
