import React from 'react'
import { BrowserRouter as Router, Route, matchPath } from 'react-router-dom'
import { StyleSheet } from 'react-native'
import Pusher from 'pusher-js'
import request from '../request'
import HomeContext from '../contexts/HomeContext'
import InvoiceContext from '../contexts/InvoiceContext'
import Header from '../components/Header'
import Users from './Users'
import Invoices from './Invoices'
import Invoice from './Invoice'
import './home.css'
import FormUserModal from '../components/FormUserModal'

const styles = StyleSheet.create({
  header: {
    zIndex: 1,
  },
})

const pusher = new Pusher('e668f1860ebad4de8d06', {
  cluster: 'ap1',
  forceTLS: true,
});

function extractUserId() {
  const match = matchPath(window.location.pathname, {
    path: '/users/:user_id',
  })
  return match
}

export default class extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      metadata: {},
      token: null,
      submitStatus: 0,
      users: [],
      newInvoices: [],
      editingUser: null,
    }

    this.submitUser = async (formValue, editUser) => {
      await this.setState({ submitStatus: 1 })
      const userWithDefaultRoles = Object.assign({}, formValue, {
        roles_mask: 1,
      })
      if (editUser) {
        if (!userWithDefaultRoles.password) {
          delete userWithDefaultRoles.password
        }
        return request.put(`/api/admin/users/${editUser.id}`, userWithDefaultRoles).then(({ data }) => {
          return this.setState(prevState => ({
            submitStatus: 2,
            users: prevState.users.map((user) => {
              if (data.id === user.id) {
                return Object.assign({}, user, data)
              }
              return user
            })
          }))
        })
      }
      return request.post('/api/admin/users', userWithDefaultRoles).then(({ data }) => {
        return this.setState(prevState => ({
          submitStatus: 2,
          users: [...prevState.users, data]
        }))
      })
    }

    this.clearNewInvoices = () => {
      this.setState({
        newInvoices: [],
      })
    }

    this.editUser = (user) => {
      this.setState({
        editingUser: user,
      })
    }

    this.deleteUser = () => {
      const deletingUserId = this.state.editingUser.id
      this.editUser(null)
      return request.delete(`/api/admin/users/${deletingUserId}`).then(() => {
        return this.setState(prevState => ({
          users: prevState.users.filter(user => user.id !== deletingUserId)
        }))
      })
    }
  }
  async componentDidMount() {
    const token = localStorage.getItem('token')
    if (token) {
      const channel = pusher.subscribe('kiuyoma-channel')
      channel.bind('invoice-created', ({ message }) => {
        const match = extractUserId()
        if (match && match.params.user_id && match.params.user_id === String(message.user_id)) {
          this.setState(prevState => ({
            newInvoices: [...prevState.newInvoices, message]
          }))
        } else {
          this.setState(prevState => ({
            users: prevState.users.map(user => {
              if (user.id === message.user_id) {
                return Object.assign({}, user, {
                  hasNewInvoice: true,
                })
              }
              return user
            })
          }))
        }
      })
      request.defaults.headers.common.Authorization = `Bearer ${token}`
      this.setState({ token })
      request.get('/api/metadata').then(({ data }) => this.setState({ metadata: data }))
      request.get('/api/admin/users').then(({ data }) => this.setState({ users: data }))
    }
  }

  render() {
    if (!this.state.token) {
      return null
    }

    return (
      <HomeContext.Provider value={{
        metadata: this.state.metadata,
      }}>
        <InvoiceContext.Provider value={{
          newInvoices: this.state.newInvoices,
          clearNewInvoices: this.clearNewInvoices,
        }}>
          <Header
            style={styles.header}
            renderUserForm={(displayUserForm, close ) => (displayUserForm || this.state.editingUser) && (
              <FormUserModal
                editingUser={this.state.editingUser}
                close={() => {
                  if (displayUserForm) {
                    close()
                  }
                  if (this.state.editingUser) {
                    this.editUser(null)
                  }
                }}
                deleteUser={this.deleteUser}
                user={this.state.editingUser}
                onSubmit={this.submitUser}
                submitStatus={this.state.submitStatus}
              />
            ) } />
          <div className="home">
            <Router>
              <div className="main-wrapper">
                <div className="first-col">
                  <Users users={this.state.users} editUser={this.editUser} />
                </div>
                <Route path="/users/:user_id" component={Invoices} />
                <div className="main-col">
                  <Route exact path="/users/:user_id/invoices/:invoice_id" component={Invoice} />
                </div>
              </div>
            </Router>
          </div>
        </InvoiceContext.Provider>
      </HomeContext.Provider>
    )
  }
}
