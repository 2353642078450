import React from 'react'
import { View, Image, StyleSheet } from 'react-native'

const logoImage = require('./logo.png')

const styles = StyleSheet.create({
  container: {
  },
  logo: {
    height: 100,
  },
})
type Props = {
  style: any,
  imageStyle: any,
}
export default ({ style, imageStyle }: Props) => (
  <View style={[styles.container, style]}>
    <Image
      style={[styles.logo, imageStyle]}
      resizeMode="contain"
      source={logoImage}
    />
  </View>
)
