import React from 'react'
import {
  View,
  StyleSheet,
} from 'react-native'
import Validator from 'validatorjs'
import en from 'validatorjs/src/lang/en'
import Button from './Button'
import TextField from './TextField'

Validator.setMessages('en', en)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})


export default class Form extends React.Component {
  static defaultProps = {
    initialValues: {},
  }
  constructor(props) {
    super(props)

    const initialFormValues = {}
    this.rules = {}
    props.fields.forEach((field) => {
      initialFormValues[field.name] = props.initialValues[field.name] || ''
      if (field.rule) {
        this.rules[field.name] = field.rule
      }
    })

    this.state = {
      values: initialFormValues,
      invalid: (() => {
        const validation = new Validator(initialFormValues, this.rules)
        return validation.fails()
      })(),
    }

    this.validate = () => {
      const validation = new Validator(this.state.values, this.rules)
      if (this.state.invalid !== validation.fails()) {
        this.setState({
          invalid: validation.fails(),
        })
      }
    }

    this.handleFieldChanged = (fieldName, value) => {
      this.setState({
        values: {
          ...this.state.values,
          [fieldName]: value,
        },
      }, () => {
        this.validate()
      })
    }

    this.handleSubmit = () => {
      this.props.onSubmit(this.state.values)
    }
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        <View style={this.props.formBodyStyle}>
          {this.props.fields.map((field) => {
            return (
              <TextField
                key={field.name}
                {...field}
                textStyle={this.props.textFieldStyle}
                value={String(this.state.values[field.name] || '')}
                onChangeText={value => this.handleFieldChanged(field.name, value)}
              />
            )
          })}
        </View>
        <Button
          onPress={this.handleSubmit}
          loading={this.props.submitting}
          {...this.props.submitButtonProps}
          disabled={this.state.invalid || this.props.submitting}
        />
      </View>
    )
  }
}
