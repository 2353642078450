import React from 'react'
import { View } from 'react-native'
import { Link } from 'react-router-dom'
import UserMenuItem from '../components/UserMenuItem'


export default ({ users, editUser }) => (
  <View>
    {users.map((user) => (
      <Link to={`/users/${user.id}`} key={user.id}>
        <UserMenuItem user={user} editUser={() => editUser(user)} />
      </Link>
    ))}
  </View>
)
