import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    paddingLeft: 10,
  },
})

export default ({ text }) => (
  <View style={styles.container}>
    <Text>{text}</Text>
  </View>
)
