import React from 'react'
import { StyleSheet } from 'react-native'
import Button from './Button'

const styles = StyleSheet.create({
  container: {
    marginRight: 5,
  },
})
export default ({ onPress }) => (
  <Button style={styles.container} onPress={onPress} caption="Create account" theme="kiuRed" />
)
